<div class="cards">

  <!-- magazine -->
  <mat-card class="can-card mat-elevation-z15">
    <mat-card-header>
      <mat-card-title>{{'download.magazine' | translate}}</mat-card-title>
    </mat-card-header>
    <img mat-card-image
         [src]="'/assets/img/magazine/'+translate.lang+'.jpg'"
         alt="Magazine">
    <mat-card-actions>
      <a mat-raised-button
         color="accent"
         [href]="'/assets/zip/magazine/Leaves_of_Autumn_'+translate.lang+'.pdf'">
        <mat-icon>cloud_download</mat-icon>
        {{'download' | translate}}
      </a>
    </mat-card-actions>
  </mat-card>

  <!-- presentation -->
  <mat-card class="can-card mat-elevation-z15">
    <mat-card-header>
      <mat-card-title>{{'download.presentation' | translate}}</mat-card-title>
    </mat-card-header>
    <img mat-card-image
         [src]="'/assets/img/plan/'+translate.lang+'.png'"
         alt="Strategic Plan Presentation">
    <mat-card-actions>

      <a mat-raised-button
         [href]="'/assets/zip/plan/Strategic_Plan_Canvassing_'+translate.lang+'.zip'"
         color="accent">
        <mat-icon>cloud_download</mat-icon>
        {{'download' | translate}}
      </a>

    </mat-card-actions>
  </mat-card>

  <!-- t-shirt -->
  <mat-card class="can-card mat-elevation-z15">
    <mat-card-header>
      <mat-card-title>{{'download.t-shirt' | translate}}</mat-card-title>
    </mat-card-header>
    <img mat-card-image
         [src]="'/assets/img/t-shirt/'+translate.lang+'.jpg'"
         alt="T-Shirt">
    <mat-card-actions>

      <a mat-raised-button
         [href]="'/assets/zip/t-shirt/t-shirt-'+translate.lang+'.zip'"
         color="accent">
        <mat-icon>cloud_download</mat-icon>
        {{'download' | translate}}
      </a>

    </mat-card-actions>
  </mat-card>

  <!-- banners -->
  <mat-card class="can-card mat-elevation-z15 last">
    <mat-card-header>
      <mat-card-title>{{'download.banners' | translate}}</mat-card-title>
    </mat-card-header>
    <img mat-card-image
         [src]="'/assets/img/banner/'+translate.lang+'.jpg'"
         alt="Banners">
    <mat-card-actions>

      <a mat-raised-button
         [href]="'assets/zip/banners/banners_'+translate.lang+'.zip'"
         color="accent">
        <mat-icon>cloud_download</mat-icon>
        {{'download' | translate}}
      </a>

    </mat-card-actions>
  </mat-card>

</div>
