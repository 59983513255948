import { Component } from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import {TranslateService} from './i18n/translate.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  langList = ['en', 'es', 'pt', 'ro', 'fr', 'de', 'ru']; // hu

  isHandset$: Observable<boolean> = this.bpObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private bpObserver: BreakpointObserver,
    private translate: TranslateService,
    private titleService: Title
  ) {
    this.setTitle();
  }

  setLang(lang: string) {
    this.translate.use(lang.toLowerCase())
      .then(() => this.setTitle())
      .catch(err => {
        console.error('Error:', err);
      });
  }

  private setTitle() {
    this.titleService.setTitle(this.translate.data['page.title'] || 'Canvassing');
  }

  get selectedLang(): string {
    return this.translate.lang;
  }

}
