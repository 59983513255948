import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  data: any = {};
  lang = 'en';

  constructor(private http: HttpClient) { }

  use(lang: string): Promise<{}> {

    this.lang = lang;
    localStorage.setItem('lang', lang);

    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang || 'en'}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.use('en');
          // resolve(this.data);
        }
      );
    });
  }
}
