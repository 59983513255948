import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OurMissionComponent} from './our-mission/our-mission.component';
import {AppealComponent} from './appeal/appeal.component';
import {PlanComponent} from './plan/plan.component';
import {DownloadsComponent} from './downloads/downloads.component';

const routes: Routes = [
  {path: '', component: OurMissionComponent},
  {path: 'appeal', component: AppealComponent},
  {path: 'plan', component: PlanComponent},
  {path: 'downloads', component: DownloadsComponent},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
