import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import { OurMissionComponent } from './our-mission/our-mission.component';
import { PlanComponent } from './plan/plan.component';
import { AppealComponent } from './appeal/appeal.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { TranslatePipe } from './i18n/translate.pipe';
import {HttpClientModule} from '@angular/common/http';
import {TranslateService} from './i18n/translate.service';
import {MarkdownModule} from 'ngx-markdown';

export function setupTranslateFactory(service: TranslateService): () => void {
  return () => service.use(localStorage.getItem('lang') || 'en');
}

@NgModule({
  declarations: [
    AppComponent,
    OurMissionComponent,
    PlanComponent,
    AppealComponent,
    DownloadsComponent,
    TranslatePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslateService ],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
