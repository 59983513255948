import { Component } from '@angular/core';
import {TranslateService} from '../i18n/translate.service';

@Component({
  selector: 'app-appeal',
  templateUrl: './appeal.component.html',
  styleUrls: ['./appeal.component.scss']
})
export class AppealComponent {

  constructor(public translate: TranslateService) { }
}
