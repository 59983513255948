<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav"
               fixedInViewport="true"
               mode="over"
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'">

    <!-- side menu -->
    <mat-toolbar>{{'nav.menu' | translate}}</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item
         routerLink="/"
         routerLinkActive="mat-accent"
         [routerLinkActiveOptions]="{exact: true}"
         (click)="drawer.close()">
        {{'nav.mission' | translate}}
      </a>
      <a mat-list-item
         routerLink="/appeal"
         routerLinkActive="mat-accent"
         (click)="drawer.close()">
        {{'nav.appeal' | translate}}
      </a>
      <a mat-list-item
         routerLink="/plan"
         routerLinkActive="mat-accent"
         (click)="drawer.close()">
        {{'nav.plan' | translate}}
      </a>
      <a mat-list-item
         routerLink="/downloads"
         routerLinkActive="mat-accent"
         (click)="drawer.close()">
        {{'nav.downloads' | translate}}
      </a>
    </mat-nav-list>

  </mat-sidenav>
  <mat-sidenav-content>

    <!-- main menu -->
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon>menu</mat-icon>
      </button>
      <a routerLink="/" class="logo">{{'page.title' | translate}}</a>
      <span class="spacer"></span>

      <!-- links -->
      <ul *ngIf="!(isHandset$ | async)">
        <li>
          <a mat-button
             routerLink="/"
             routerLinkActive="mat-accent"
             [routerLinkActiveOptions]="{exact: true}">
            {{'nav.mission' | translate}}
          </a>
        </li>
        <li>
          <a mat-button
             routerLink="/appeal"
             routerLinkActive="mat-accent">
            {{'nav.appeal' | translate}}
          </a>
        </li>
        <li>
          <a mat-button
             routerLink="/plan"
             routerLinkActive="mat-accent">
            {{'nav.plan' | translate}}
          </a>
        </li>
        <li>
          <a mat-button
             routerLink="/downloads"
             routerLinkActive="mat-accent">
            {{'nav.downloads' | translate}}
          </a>
        </li>
      </ul>

      <!-- languages -->
      <!--<button mat-button [matMenuTriggerFor]="lMenu">
        <img src="/assets/img/flags/{{selectedLang}}.png" [alt]="selectedLang.toUpperCase()">
        {{selectedLang.toUpperCase()}}
      </button>
      <mat-menu #lMenu class="lang-menu">
        <button mat-menu-item
                *ngFor="let lang of langList"
                (click)="setLang(lang)">
          <img src="/assets/img/flags/{{lang}}.png"
               [alt]="lang.toUpperCase()"
               style="vertical-align: middle; margin-right: 3px;">
          {{lang.toUpperCase()}}
        </button>
      </mat-menu>-->
    </mat-toolbar>

    <!-- container -->
    <div class="container">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
