<mat-card class="can-card mat-elevation-z15">

  <img mat-card-image
       [src]="'/assets/img/banner/'+translate.lang+'.jpg'"
       alt="Like Leaves of Autumn">
  <mat-card-content>

    <article class="box">
      <section class="text">
        <!--<img src="/assets/img/globe.png"
             alt="Globe"
             class="globe">-->

        <markdown [src]="'assets/i18n/mission/'+translate.lang+'.md'"></markdown>
      </section>

      <!-- announcement -->
      <mat-card class="mat-elevation-z15 announcement">
        <mat-card-header>
          <mat-card-title>
            <a href="/assets/img/announcement/{{translate.lang}}.jpg">
              Announcement
            </a>
          </mat-card-title>
        </mat-card-header>

        <a href="/assets/img/announcement/{{translate.lang}}.jpg"
           mat-card-image>
          <img [src]="'/assets/img/announcement/'+translate.lang+'.jpg'"
               alt="Announcement">
        </a>
      </mat-card>
    </article>

  </mat-card-content>
</mat-card>
