import { Component } from '@angular/core';
import {TranslateService} from '../i18n/translate.service';

@Component({
  selector: 'app-our-mission',
  templateUrl: './our-mission.component.html',
  styleUrls: ['./our-mission.component.scss']
})
export class OurMissionComponent {

  constructor(public translate: TranslateService) { }

}
