import { Component } from '@angular/core';
import {TranslateService} from '../i18n/translate.service';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent {

  constructor(public translate: TranslateService) { }
}
