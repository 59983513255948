import { Component } from '@angular/core';
import {TranslateService} from '../i18n/translate.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent {

  constructor(public translate: TranslateService) { }

}
